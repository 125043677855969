export const LOGIN_PENDING = 'LOGIN_PENDING';

export const CHARGE_LATEST_APPLIST = 'CHARGE_LATEST_APPLIST';
export const CHARGE_TWEAKED_APPLIST = 'CHARGE_TWEAKED_APPLIST';
export const CHARGE_POPULAR_APPLIST = 'CHARGE_POPULAR_APPLIST';

export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';